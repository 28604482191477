import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { useNavigate, Link } from 'react-router-dom';
import SubscriptionCard from './SubscriptionCard';
import { RiArrowRightDoubleLine } from "react-icons/ri";
import '../CSS File/CarouselComponent.css';
import toast from 'react-hot-toast';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const SubscriptionPlans = ( isOpen,  onClose ) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [pricingOptions, setPricingOptions] = useState([]);
  const [currentPlan, setCurrentPlan] = useState({});
  const navigate = useNavigate(); 

  const planPrice = localStorage.getItem("price");
  const userId = localStorage.getItem("logged_user_id");
  const location = useLocation();
  const { postalCode } = location.state || {}; // Retrieve zipCode from state

  useEffect(() => {
    console.log(postalCode);
    console.log(isOpen);
    
    
    const fetchData = async () => {
      await fetchSubscriptionData();
      await checkSubscription();
    };
fetchData();
  }, [ ]);
  

  useEffect( () => {
    debugger
    const currentPlanIndex = pricingOptions.findIndex(option => option.price === planPrice??currentPlan.price );
    console.log(JSON.stringify(currentPlan)  );
    console.log( currentPlan.price);
    console.log(planPrice);
    setActiveIndex(currentPlanIndex );
    console.log(activeIndex);
  }, [currentPlan, pricingOptions]);

  const fetchSubscriptionData = async () => {

    try {
      let response = await fetch(
        `${process.env.REACT_APP_BASE_URL}subscription/fetch_subscription_plan`,
        

        {
          method: 'POST',
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            postal_code:  localStorage.getItem('postalCode') || postalCode,
          }),
        }
  
      );
      let responseData = await response.json();
      setPricingOptions(responseData.data || []);
    } catch (error) {
      console.error('Error fetching subscription data:', error);
    }
  };

  const setUserSubscription = async (id , Default) => {

    debugger
    if (userId) {
      try {
        let response = await fetch(
          `${process.env.REACT_APP_BASE_URL}subscription/add_subscription_plan`,
          {
            method: 'POST',
            headers: {
              'Authorization': 'Bearer ' + localStorage.getItem('token'),
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              plan_id: `${id}`,
              user_id: `${localStorage.getItem('logged_user_id')}`,
            }),
          }
        );
        let responseData = await response.json();
    
        if (responseData.status) {

debugger
            if (Default === '1') {
                localStorage.setItem("plan_status", "false"); // Store a specific value for id === 3
            } else  {
                localStorage.setItem("plan_status", "true"); // Store a different value for other cases
            }
        
        

          if(responseData.regular_plan){
            navigate('/chef-list');
          }else{
            navigate('/add-Address-details');
          }
        
  
        } 
        else {
          toast.error('Please login before subscribing, then apply Subscription Plan');
        }

      } catch (error) {
        console.error('Error fetching subscription data:', error);
      }
    }
    
    else {

        try {
          let response = await fetch(
            `${process.env.REACT_APP_BASE_URL}subscription/fetch_plan_details`,
            {
              method: 'POST',
              headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                plan_id: `${id}`,
              }),
            }

          );
          let responseData = await response.json();
      


          if (responseData.status) {
  
            localStorage.setItem("chef_status", responseData.chef_status);
            localStorage.setItem("status", responseData.status);
            localStorage.setItem("plan_status", responseData.plan_status);
            localStorage.setItem("plan_id", responseData.plan_id);
            localStorage.setItem("plan_name", responseData.plan_name);
            localStorage.setItem("price", responseData.price);
            navigate('/add-Address-details');
          } else {
            toast.error('Please login before subscribing, then apply Subscription Plan');
          }
        } catch (error) {
          console.error('Error fetching subscription data:', error);
        }
      
    }
  };
 
  const checkSubscription = async () => {
    try {
      let response = await fetch(
        `${process.env.REACT_APP_BASE_URL}subscription/check_current_plan`,
        {
          method: 'POST',
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            user_id: `${localStorage.getItem('logged_user_id')}`,
              "postal_code": `${localStorage.getItem("postalCode")}`
          }),
        }
      );
      const res_json = await response.text();
      const currentPlanData = JSON.parse(res_json);
      if (currentPlanData.status) {
        setCurrentPlan(currentPlanData);
      } else {
        console.error('Error while fetching data, please try later');
      }
    } catch (error) {
      console.error('Error checking subscription:', error);
    }
  };

  const settings = {
    centerMode: true,
    centerPadding: '0px',
    slidesToShow: 3,
    focusOnSelect: true,
    beforeChange: (current, next) => setActiveIndex(next),
  };

  return (
    <>
    <div className='container'>
    <div className="App">
        <Link
          to={localStorage.getItem('logged_user_id') ? '/chef-list' : '/sign-up'}
          style={{ textDecoration: 'none' }}
        >
          <div className="skip mt-0">
            skip
            <span>
              <RiArrowRightDoubleLine /> 
            </span>
          </div>
        </Link>
        <div className="pricing-container">
          {pricingOptions.map((option, index) => (
            <SubscriptionCard
              key={option.plan_id}
              activeIndex={activeIndex}
              index={index}
              type={option.plan_name}
              price={option.price}
              features={option.description}
              buttonText={option.button_text}
              // popular={option.populor}
              onSubscribe={() => setUserSubscription(option.subscription_plan_id , option.default_plan)}
              changeCard={() => setActiveIndex(index)}
            />
            
          ))}
        </div>
        <div className="cancel-subscription-plan text-center mt-5"
          style={{ color: '#FF5E41', display: currentPlan.plan_status ? 'block' : 'none' }}
        >
          Cancel Subscription Anytime
        </div>
      </div>

      <div className='mobile'>
      <Link
          to={localStorage.getItem('logged_user_id') ? '/chef-list' : '/sign-up'}
          style={{ textDecoration: 'none' }}
        >
          <div className="skip mt-0">
            skip
            <span>
              <RiArrowRightDoubleLine />
            </span>
          </div>
        </Link>

    {pricingOptions.map((option, index) => (
  <div className='div my-3'>
      <div  key={option.subscription_plan_id}    className={`mobile-card ${index === activeIndex ? 'active' : ''}`}  onClick={() => setActiveIndex(index)}>
        {/* {option.popular && <div className="popular-badge">Popular</div>} */}
        <div className="card-header-mobile text-center">
          <h3>{option.plan_name}</h3>
          <div className='price'>${option.price}/booking</div>
        </div>
      
        <ul className="card-features" style={{listStyleType:"disc", paddingLeft:"20px"}}>
          {option.description.map((feature, idx) => (
            <li key={idx}>{feature}</li>
          ))}
        </ul>
        <div className='row text-center'>

        <button className="card-button-mobile" onClick={() => setUserSubscription(option.subscription_plan_id)}>
            {option.button_text}
          </button>
        {/* <div className='col-5'>
              <div className='price'>${option.price}/booking</div>
            </div> */}
            {/* <div className='col-12'>
            
            </div>
         */}
        </div>
      </div>
      </div>
    ))}
  </div>
    </div>
 

    </>
  );
};

export default SubscriptionPlans;

