import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoggedNav from "../components/LoggedNav";
import { FaCheck } from 'react-icons/fa';
import { isMobile } from "react-device-detect";

function BookRequestSent(props) {
  const [planDetails, setPlanData] = useState([]); // Use camelCase for consistency

  const navigate = useNavigate();

  useEffect(() => {
    const checkSubscription = async () => {
      try {
        let response = await fetch(
          `${process.env.REACT_APP_BASE_URL}subscription/check_current_plan`,
          {
            method: 'POST',
            headers: {
              "Authorization": "Bearer " + localStorage.getItem("token"),
              "Content-Type": "application/json"
            },
            body: JSON.stringify({
              "user_id": localStorage.getItem("logged_user_id"),
                "postal_code": `${localStorage.getItem("postalCode")}`
            })
          }
        );

        const resJson = await response.text();
        const currentPlan = JSON.parse(resJson);

        if (currentPlan.status === true) {
          console.log(currentPlan);
          setPlanData(currentPlan);
        } else {
          console.error('Error: Response status is false');
        }
      } catch (error) {
        console.error('Error while fetching data, please try later:', error);
      }
    };

    checkSubscription();

    const title = "Booking Request Confirm | CHEF REPUBLIC";
    const desc = "";
    const titleTag = document.querySelector('title');
    titleTag.innerText = title;

    const metaTitle = document.querySelector("meta[name='title']");
    metaTitle.setAttribute('content', title);

    const metaDescription = document.querySelector("meta[name='description']");
    metaDescription.setAttribute('content', desc);

    const metaTitleOG = document.querySelector("meta[property='og:title']");
    metaTitleOG.setAttribute('content', title);

    const metaDescriptionOG = document.querySelector("meta[property='og:description']");
    metaDescriptionOG.setAttribute('content', desc);

    const metaTitleTwitter = document.querySelector("meta[property='twitter:title']");
    metaTitleTwitter.setAttribute('content', title);

    const metaDescriptionTwitter = document.querySelector("meta[property='twitter:description']");
    metaDescriptionTwitter.setAttribute('content', desc);

    setTimeout(() => {
      console.log(planDetails);

      if (planDetails.plan_id) {
        navigate("/subscription-summary");

      
      } else {
        navigate("/chef-list");
      }
    }, 5000);

  }, [planDetails, navigate]); // Add dependencies to useEffect

  return (
    <>
      <LoggedNav customer={props.customer} />
      <div className="container d-flex justify-content-center align-items-center" style={{ height: "90vh" }}>
        <div className="d-flex justify-content-center align-items-center flex-column" style={{ height: "350px", width: isMobile ? "90%" : "390px", boxShadow: isMobile ? "" : "0px 0px 8px rgba(0, 0, 0, 0.1)", borderRadius: isMobile ? "" : "20px", padding: "28px" }}>
          <FaCheck size="12rem" className="check_symbol" />
          <h5 className="text-center mt-3" style={{ fontWeight: 700, width: isMobile ? "100%" : "90%" }}>Booking request has been sent to Private Chef</h5>
          <p className="text-center mt-2" style={{ width: isMobile ? "100%" : "90%" }}>The Private Chef will reach out to you soon.</p>
        </div>
      </div>
    </>
  );
}

export default BookRequestSent;
