import React, { useState } from "react";
import { RxCross1 } from "react-icons/rx";
import { useNavigate } from "react-router-dom"; // Corrected import for navigate
import { isMobile } from "react-device-detect";
import SubscriptionPlans from "./SubscriptionPlans";

const ZipCodeDialog = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');

  const [postalCode, setZipCode] = useState(""); // state for zip code input

  const dialogOverlayStyles = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    display: isOpen ? "flex" : "none", // Use `isOpen` prop to control visibility
    alignItems: "center",
    justifyContent: "center",
    backdropFilter: "blur(4px)",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1000,
  };

  const dialogBoxStyles = {
    width: isMobile ? "75%" : "25.33%",
    backgroundColor: "#FAFAFA",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
    padding: "20px",
  };

  const dialogHeaderStyles = {
    display: "flex",
    justifyContent: "flex-end",
  };

  const closeButtonStyles = {
    width: "24px",
    height: "24px",
    backgroundColor: "#FF5E41",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  };

  const closeIconStyles = {
    color: "#ffffff",
  };

  const dialogContentStyles = {
    marginTop: "15px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    fontSize: "16px",
    color: "black",
  };

  const submitButtonStyles = {
    border: "none",
    padding: "5px",
    height: "2.25rem",
    width: "200px",
    backgroundColor: "#FF5E41",
    borderRadius: "1rem",
    color: "#ffffff",
    textAlign: "center",
    marginTop: "30px",
    cursor: "pointer",
  };

  const handleSubmitZipCode = async () => {
    if (!postalCode || postalCode.length < 5) {
      return alert("Please enter a valid Zip Code");
    }
  debugger
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}subscription/fetch_subscription_plan`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ postal_code: postalCode || localStorage.getItem('postalCode') }),
      });
  
      const responseData = await response.json();
      setErrorMessage(responseData.messages);
  
      if (responseData.status) {
        localStorage.setItem("postalCode", postalCode);
        onClose(postalCode);
        navigate("/subscription-plans", { state: { postalCode } });
      }
      else {
        debugger
        setErrorMessage(responseData.messages);
      } 
    } catch (error) {
      console.error('Error:', error);
      setErrorMessage('An error occurred. Please try again later.');
    }
  };
  
  



  return (
    <div style={dialogOverlayStyles}>
      <div style={dialogBoxStyles}>
        <div style={dialogHeaderStyles}>
          <div style={closeButtonStyles} onClick={() => onClose()}>
            <span style={closeIconStyles}>
              <RxCross1 />
            </span>
          </div>
        </div>
        <div style={dialogContentStyles}>
          <input
            type="number"
            placeholder="Enter Zip Code"
            value={postalCode}
            onChange={(e) => {
              const inputValue = e.target.value;
            
              // Check if the input contains only numeric characters
              if (!/^\d*$/.test(inputValue)) {
                setErrorMessage("Zip Code must contain only numbers.");
              } else if (inputValue.length > 5) {
                setErrorMessage("Zip Code cannot be more than 5 digits.");
              } else {
                setErrorMessage("");
                setZipCode(inputValue);
              }
            }}
            
            className="zip-input"
            style={{
              marginTop: "15px",
              padding: "10px",
              borderRadius: "5px",
              border: "1px solid #D3D3D3",
            }}
          />
          <button style={submitButtonStyles} onClick={handleSubmitZipCode}>
            Submit
          </button>

          {errorMessage && (
        <div
          style={{
            color: "red",
            marginTop: "10px",
            fontSize: "14px",
            fontWeight: "bold",
          }}
        >
          {errorMessage}
        </div>
      )}
        </div>
      </div>
    </div>
  );
};

export default ZipCodeDialog;



// import React, { useState } from "react";
// import { RxCross1 } from "react-icons/rx";
// import { useNavigate } from "react-router-dom";
// import { Formik, ErrorMessage } from "formik";
// import * as Yup from "yup";

// const ZipCodeDialog = ({ isOpen, onClose }) => {
//   const navigate = useNavigate();
//   const [errorMessage, setErrorMessage] = useState("");

//   const dialogOverlayStyles = {
//     position: "fixed",
//     top: 0,
//     left: 0,
//     width: "100vw",
//     height: "100vh",
//     display: isOpen ? "flex" : "none",
//     alignItems: "center",
//     justifyContent: "center",
//     backdropFilter: "blur(4px)",
//     backgroundColor: "rgba(0, 0, 0, 0.5)",
//     zIndex: 1000,
//   };

//   const dialogBoxStyles = {
//     width: "90%",
//     maxWidth: "400px",
//     backgroundColor: "#FAFAFA",
//     borderRadius: "10px",
//     display: "flex",
//     flexDirection: "column",
//     boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
//     padding: "20px",
//   };

//   const closeButtonStyles = {
//     width: "24px",
//     height: "24px",
//     backgroundColor: "#FF5E41",
//     borderRadius: "50%",
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//     cursor: "pointer",
//     alignSelf: "flex-end",
//   };

//   const closeIconStyles = {
//     color: "#ffffff",
//   };

//   const validationSchema = Yup.object().shape({
//     address: Yup.string().required("Address is required."),
//     zipCode: Yup.string()
//       .matches(/^\d{5}$/, "Zip Code must be exactly 5 digits.")
//       .required("Zip Code is required."),
//   });

//   const handleFormSubmit = async (values) => {
//     try {
//       const response = await fetch(
//         `${process.env.REACT_APP_BASE_URL}subscription/fetch_subscription_plan`,
//         {
//           method: "POST",
//           headers: {
//             Authorization: `Bearer ${localStorage.getItem("token")}`,
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify(values),
//         }
//       );

//       const responseData = await response.json();
//       setErrorMessage(responseData.messages || "");

//       if (responseData.status) {
//         localStorage.setItem("postalCode", values.zipCode);
//         onClose(values.zipCode);
//         navigate("/subscription-plans", { state: { postalCode: values.zipCode } });
//       } else {
//         setErrorMessage(responseData.messages || "An error occurred.");
//       }
//     } catch (error) {
//       console.error("Error:", error);
//       setErrorMessage("An error occurred. Please try again later.");
//     }
//   };

//   return (
//     <div style={dialogOverlayStyles}>
//       <div style={dialogBoxStyles}>
//         <div style={closeButtonStyles} onClick={() => onClose()}>
//           <span style={closeIconStyles}>
//             <RxCross1 />
//           </span>
//         </div>
//         <h5 style={{ textAlign: "center", color: "#1E1E1E", fontWeight: 700 }}>
//           Enter Your Full Address
//         </h5>

//         <h6>Please Enter Zipcode of Service </h6>
//         <Formik
//   initialValues={{ address: "", zipCode: "" }}
//   validationSchema={validationSchema}
//   onSubmit={handleFormSubmit}
// >
//   {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => {
//     // Determine whether the button should be disabled
//     const isButtonDisabled = !values.address || values.zipCode.length !== 5;

//     return (
//       <form onSubmit={handleSubmit} style={{ marginTop: "20px" }}>
//         <div className="mb-3">
//           <textarea
//             name="address"
//             placeholder="Enter your address"
//             rows={3}
//             className={`form-control shadow-none ${
//               errors.address && touched.address ? "invalid" : ""
//             }`}
//             onChange={handleChange}
//             onBlur={handleBlur}
//             value={values.address}
//           />
//           <ErrorMessage
//             name="address"
//             component="div"
//             style={{ color: "red", fontSize: "14px" }}
//           />
//         </div>
//         <div className="mb-3">
//           <input
//             type="text"
//             name="zipCode"
//             placeholder="Enter Zip Code"
//             className={`form-control shadow-none ${
//               errors.zipCode && touched.zipCode ? "invalid" : ""
//             }`}
//             onChange={handleChange}
//             onBlur={handleBlur}
//             value={values.zipCode}
//           />
//           <ErrorMessage
//             name="zipCode"
//             component="div"
//             style={{ color: "red", fontSize: "14px" }}
//           />
//         </div>
//         <button
//           type="submit"
//           className="btn btn-orange w-100"
//           style={{
//             backgroundColor: isButtonDisabled ? "#D3D3D3" : "#FF5E41", // Conditional background
//             // border: "none",
//             borderRadius: "25px",
//             color: isButtonDisabled ? "#A9A9A9" : "#FFF", // Conditional text color
//             padding: "10px",
//             cursor: isButtonDisabled ? "not-allowed" : "pointer", // Conditional cursor
//             fontWeight: "bold",
//             pointerEvents: isButtonDisabled ? "none" : "auto", // Prevent hover interaction when disabled
//             border: `2px solid ${isButtonDisabled ?   "#FF5E41" :"#D3D3D3"}`,
//           }}
//           disabled={isButtonDisabled} // Disable if fields are empty
//         >
//           Confirm
//         </button>

//         {errorMessage && (
//           <div
//             style={{
//               color: "red",
//               marginTop: "10px",
//               textAlign: "center",
//             }}
//           >
//             {errorMessage}
//           </div>
//         )}
//       </form>
//     );
//   }}
// </Formik>

//       </div>
//     </div>
//   );
// };

// export default ZipCodeDialog;

