import $ from 'jquery';
import React, { useEffect, useState } from "react";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { isMobile } from "react-device-detect";
import { MdLocationOn } from "react-icons/md";
import { RiRestaurantLine } from "react-icons/ri";
import ChefCard from "../components/ChefCard";
import Footer from "../components/Footer";
import LoggedNav from "../components/LoggedNav";
import Nav from "../components/Nav";
import Spinner from "./BePatient";
import getLatLong from '../helpers/getLatLong';
import toast from 'react-hot-toast';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { RiCloseLine } from "react-icons/ri";
import { useLocation } from 'react-router-dom';
import chefImage from "../images/chef.svg";
import ContactUsPop from "../components/ContactUsPop";


function ChefList(props) {
  const [chefs, setChefs] = useState([]);
  const [diets, setDiets] = useState([]);
  const [showFilter, setShowFilter] = useState(false);


  const [planName, setplaname] = useState();
  const [addressFromHomePage, setAddress] = useState();
  const { state } = useLocation();
  const messagesEndRef = React.useRef(null);
  const scrollToTop = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const [addressLoading, setAddressLoading] = useState(false);

  const [openAddressDialog, setOpenAddressDialog] = useState(false);

  const [filterAddress, setFilterAddress] = useState({
    postal_code:  localStorage.getItem('postalCode'),
    line1: localStorage.getItem('address') ,
    country: 'US',
    latitude:  '',
    longitude:''
  });

  React.useLayoutEffect(() => {
    scrollToTop();
  });



  useEffect(() => {
if (addressFromHomePage) {
  setFilterAddress({
    postal_code: addressFromHomePage.postal_code?addressFromHomePage?.postal_code: localStorage.getItem('postalCode'),
    line1: addressFromHomePage.line1?addressFromHomePage?.line1 :localStorage.getItem('address') ,
    country: 'US',
    latitude: addressFromHomePage?.latitude ?? '',
    longitude: addressFromHomePage?.longitude ?? ''
  })
  handleAddressSubmit();
}else{
  setFilterAddress({
    postal_code:  localStorage.getItem('postalCode'),
    line1: localStorage.getItem('address') ,
    country: 'US',
    latitude: addressFromHomePage?.latitude ?? '',
    longitude: addressFromHomePage?.longitude ?? ''
  })
  handleAddressSubmit();
}


  
    var title = "Browse Chef | CHEF REPUBLIC";
    var desc = "Browse and select Private Chefs based on the date of service and preferred cuisine/diet selected";
    const titleTag = document.querySelector('title');
    titleTag.innerText = title;
    const metaTitle = document.querySelector("meta[name='title']");
    metaTitle.setAttribute('content', title);
    const metaDescription = document.querySelector("meta[name='description']");
    metaDescription.setAttribute('content', desc);
    const metaTitleOG = document.querySelector("meta[property='og:title']");
    metaTitleOG.setAttribute('content', title);
    const metaDescriptionOG = document.querySelector("meta[property='og:description']");
    metaDescriptionOG.setAttribute('content', desc);
    const metaTitleTwitter = document.querySelector("meta[property='twitter:title']");
    metaTitleTwitter.setAttribute('content', title);
    const metaDescriptionTwitter = document.querySelector("meta[property='twitter:description']");
    metaDescriptionTwitter.setAttribute('content', desc);
    getUserPlans();


    setLoading(true);
    Promise.all([updateChefs(), updateDiets(), getStatusList(), customerDetails()])
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error occurred while loading data:', error);
        setLoading(false);
      });

      if(filterAddress==null){
        console.log(`${addressFromHomePage.zipCode}`.length);
      const { data } = state;
      setAddress(data?? null);
      console.log(addressFromHomePage);
      }
      console.log(filterAddress);
  
  }, [addressFromHomePage]);


  let date_arr = localStorage.getItem("booking_date")?.split("/");
  let date_obj = date_arr ? new Date(date_arr[2] + "-" + date_arr[0] + "-" + date_arr[1]) : new Date();

  const [value, onChange] = useState(date_obj);
  const [date, setDate] = useState(localStorage.getItem("booking_date"));
  const [cuisine, setCuisine] = useState(localStorage.getItem("food_categories"));
  const [loading, setLoading] = useState(true);

  const setLocalDate = async (value) => {
    let month = "";
    let date = "";
    if ((value.getMonth() + 1) < 10) {
      month = "0" + (value.getMonth() + 1).toString();
    } else {
      month = (value.getMonth() + 1).toString();
    }
    if (value.getDate() < 10) {
      date = "0" + (value.getDate()).toString();
    } else {
      date = (value.getDate()).toString();
    }
    let us_date_string = month + "/" + date + "/" + value.getFullYear().toString();
    localStorage.setItem("booking_date", us_date_string);
    $("#modalDate").click();
    setDate(us_date_string);
  };

  const updateDiets = async () => {
    let data = await fetch(`${process.env.REACT_APP_BASE_URL}general/categories`);
    let parsedData = await data.json();
    setDiets(parsedData.data);
  };

  const getStatusList = async () => {
    let data = await fetch(
      `${process.env.REACT_APP_BASE_URL}status/all_status`
    );
    let parsedData = await data.json();
    for (let index = 0; index < parsedData.data.length; index++) {
      const element = parsedData.data[index];
      localStorage.setItem(element.name, element.color);
    }
  };

  const customerDetails = async () => {
    if (localStorage.getItem("token")) {
      let response = await fetch(
        `${process.env.REACT_APP_BASE_URL}user/loggedin_customer_detail`,
        {
          method: "POST",
          headers: { Authorization: "Bearer " + localStorage.getItem("token") }
        }
      );
      const res_json = await response.text();
      const data = JSON.parse(res_json);
      if (data.status === true) {
        console.log(data.data[0]);
        var details = data.data[0];
        setFilterAddress({ ...filterAddress, postal_code: details.postal_code, line1: details.customer_address });
      } else {
        toast.error('Error while fetching data, please try later');
      }
    }
  };


  let getUserPlans=async()=>{
    if(localStorage.getItem("logged_user_id")){
    try {
      let response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/subscription/check_current_plan`,
        {
          method: "POST",
     headers: {
            "Authorization": "Bearer " + localStorage.getItem("token"),
            "Content-Type": "application/json",
          },
           body: JSON.stringify({
            "user_id":`${localStorage.getItem("logged_user_id")}`,    
            "postal_code": `${localStorage.getItem("postalCode")}`               
           })
                                        
        }
      );
      let responseData = await response.json();
     if(responseData.status){
      console.log(responseData.plan_name);
      setplaname(responseData.plan_name);
     }else{
      toast.error("Sorry unable to fetch")
     }

    } catch (error) {
      console.error("Error fetching subscription data:", error);
}
    }else{
      try {
        let response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/subscription/fetch_plan_details`,
          {
            method: "POST",
       headers: {
              "Authorization": "Bearer " + localStorage.getItem("token"),
              "Content-Type": "application/json",
            },
             body: JSON.stringify({
              plan_id:`${localStorage.getItem("plan_id")}`,                   
             })
                                          
          }
        );
        let responseData = await response.json();
       if(responseData.status){
        console.log(responseData.plan_name);
        setplaname(responseData.plan_name);
       }else{
        toast.error("Sorry unable to fetch")
       }
  
      } catch (error) {
        console.error("Error fetching subscription data:", error);
  }
    }
}

  const handleAddressSubmit = async (e) => {

    debugger
    if((e))
      {console.log(e);
      e.preventDefault();
    }

  if (filterAddress.postal_code.length !== 5) {
    toast.error('Please enter a valid Zip code');
    return;
  }

 
  
    setAddressLoading(true);
  debugger


  if(localStorage.getItem('logged_user_id')){
    if (filterAddress.postal_code) {
      setFilterAddress({
   ...filterAddress, 
        latitude:'',
        longitude: ''
      })
    }
  }

    try {
      const coordinates = await getLatLong(filterAddress);
      console.log(coordinates);
      if (coordinates) {
        setFilterAddress({ ...filterAddress, latitude: coordinates.latitude, longitude: coordinates.longitude });
        await updateChefs(false, { latitude: coordinates.latitude, longitude: coordinates.longitude });
        setOpenAddressDialog(false);
      } else {
        console.log('Failed to retrieve coordinates.');
        toast.error('Please enter a valid proper address or double-check your address');
        return;
      }
    } catch (error) {
      setAddressLoading(false);
      console.error('Error:', error);
      toast.error('Failed to fetch precise location, try again later.');
      return;
    } finally {
      setAddressLoading(false);
    }

  };

  const updateChefs = async (clearFilter = false, addressLatLong = null) => {
    var category_id = '';
    var date = '';
    var user_address_longitude = '';
    var user_address_latitude = '';

    if (!clearFilter) {
      let food_categories = localStorage.getItem("food_categories");
      let booking_date = localStorage.getItem("booking_date");

      category_id = food_categories ? food_categories.split("-")[0] : "";
      date = booking_date ? booking_date : "";

      if (addressLatLong) {
        user_address_longitude = addressLatLong.longitude;
        user_address_latitude = addressLatLong.latitude;
      } else {
        user_address_longitude = filterAddress.postal_code === '' ? '' : filterAddress.longitude;
        user_address_latitude = filterAddress.postal_code === '' ? '' : filterAddress.latitude;
      }
    }

    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var date_format_for_api = "";
    if (date) {
      var date_array = date.split("/");
      date_format_for_api = date_array[1] + "-" + date_array[0] + "-" + date_array[2];
    }
    var raw = JSON.stringify({
      "category_id": category_id??'',
      "date": date_format_for_api??"",
      "user_id": localStorage.getItem('logged_user_id') ?? '',
      "user_address_longitude": user_address_longitude?? "",
      "user_address_latitude": user_address_latitude?? "",
    }); 


    

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };


    let response = await fetch(`${process.env.REACT_APP_BASE_URL}general/get_chef_list_by_filter`, requestOptions);
    let parsedData = await response.json();
    setChefs(parsedData.data);
    if (localStorage.getItem("food_categories") || localStorage.getItem("booking_date") || filterAddress.postal_code) {
      setShowFilter(true);
    }
    setLoading(false);
  };

  const filterButtonStyle = {
    width: "100%",
    borderRadius: "30px",
    padding: "10px",
    background: "#fff",
    border: "none",
    fontSize: "0.9rem",
    fontWeight: 400
  };

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <div ref={messagesEndRef} />

          {localStorage.getItem("token") ? (
            <LoggedNav customer={props.customer} main={true} />
          ) : (
            <Nav customer={props.customer} />
          )}

          <Dialog
            fullWidth={true}
            maxWidth="sm"
            onClose={() => setOpenAddressDialog(false)}
            open={openAddressDialog}
          >
            <DialogTitle>Find chefs in your area</DialogTitle>
            <IconButton
              aria-label="close"
              onClick={() => setOpenAddressDialog(false)}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <RiCloseLine />
            </IconButton>
            <DialogContent dividers>
              <form onSubmit={handleAddressSubmit}>
                <div className="modal-body ">
                  <div className="mb-3 text-center text-md-start">
                    <input
                      className="form-control shadow-none"
                      placeholder="Address *"
                      type="text"
                      name="line1"
                      required
                      value={filterAddress.line1}
                      onChange={(e) =>
                        setFilterAddress({
                          ...filterAddress,
                          line1: e.target.value,
                        })
                      }
                    />
                    <p
                      className="mt-2 mb-0 ms-md-3"
                      style={{ fontSize: "0.7rem" }}
                    >
                      A precise address helps locate Nearby Chefs more
                      accurately.
                    </p>
                  </div>

                  <div className="mb-3">
                    <input
                      className="form-control shadow-none"
                      autoFocus
                      placeholder="Zip Code *"
                      type="number"
                      name="postal_code"
                      maxLength={5}
                      minLength={5}
                      required
                      value={filterAddress.postal_code}
                      onChange={(e) =>
                        setFilterAddress({
                          ...filterAddress,
                          postal_code: e.target.value,
                        })
                      }
                    />
                  </div>

                  <div className="mb-3">
                    <input
                      className="form-control shadow-none"
                      placeholder="Country"
                      type="text"
                      name="country"
                      style={{ color: "rgb(108, 117, 125)" }}
                      disabled={true}
                      value={filterAddress.country}
                    />
                  </div>
                </div>

                <div className="modal-footer d-flex justify-content-center align-items-center m-0">
                  <button
                    className="btn-orange"
                    type="submit"
                    disabled={addressLoading}
                  >
                    {addressLoading && (
                      <span className="spinner-border spinner-border-sm me-2"></span>
                    )}{" "}
                    Apply
                  </button>
                </div>
              </form>
            </DialogContent>
          </Dialog>

          {/* ------ Modal for Date Selection ------- */}

          <div
            className="modal fade"
            id="dateSelectModal"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    id="modalDate"
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body d-flex justify-content-center align-items-center m-0">
                  <Calendar
                    onChange={onChange}
                    value={value}
                    minDate={new Date()}
                  />
                </div>
                <div className="modal-footer d-flex justify-content-center align-items-center m-0">
                  <button
                    onClick={() => setLocalDate(value)}
                    className="btn-orange"
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* ------ Modal for Cuisine Diet Type Selection ------- */}

          <div
            className="modal fade"
            id="CuisineSelectModal"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    id="modalCuisine"
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div
                  className="modal-body d-flex justify-content-center align-items-center m-0"
                  id="profile-chef-complete"
                >
                  <div className="py-5 row row-cols-md-5 row-cols-sm-2 d-flex justify-content-center">
                    {diets && diets.length > 0
                      ? diets.map((element, index) => {
                          return (
                            <div className="col-6 col-md-4 my-2" key={index}>
                              <input
                                type="radio"
                                id={"card-" + element.id.toString()}
                                value={element.id + "-" + element.category_name}
                                onChange={() => {
                                  $(".validation").html("");
                                }}
                                name="food_categories"
                                className="Send_data input-hidden"
                                defaultChecked={
                                  localStorage.getItem("food_categories")
                                    ? element.id ===
                                      localStorage
                                        .getItem("food_categories")
                                        .split("-")[0]
                                    : null
                                }
                              />
                              <label
                                className="time-availablibilty-card"
                                htmlFor={"card-" + element.id.toString()}
                                style={{
                                  backgroundImage: `url(${element.category_image})`,
                                  backgroundSize: "cover",
                                  backgroundPosition: "center",
                                }}
                              >
                                {element.category_name}
                              </label>
                            </div>
                          );
                        })
                      : null}
                  </div>
                </div>
                <div className="modal-footer d-flex justify-content-center align-items-center m-0">
                  <button
                    onClick={() => {
                      localStorage.setItem(
                        "food_categories",
                        $("input[name='food_categories']:checked").val()
                      );
                      $("#modalCuisine").click();
                      setCuisine(
                        $("input[name='food_categories']:checked").val()
                      );
                      updateChefs();
                    }}
                    className="btn-orange"
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* ----- Main Code ------ */}

          <div
            className="d-flex align-items-center justify-content-center flex-column py-5"
            style={{
              backgroundColor: "rgba(237, 237, 237, 1)",
              height: "auto",
            }}
            id="customercover"
          >
            <h1 className="text-center mt-4 fw-bold">
              On Demand <br />
              Home Chef Service
            </h1>
            {/* <h4
              style={{
                fontStyle: "normal",
                width: isMobile ? "90%" : "",
                fontWeight: "400",
                fontSize: "1.1rem",
                textAlign: "center",
              }}
            >
              Hire Private Chef for Weekly Meal Prep for a flat rate of
            </h4> */}

            <h5 className="text-center mt-3 px-5 px-md-0">
              {/* Hire Private Chef to prep upto 16 fresh homemade healthy meals, all for */}
              Book Your Home Chef to Cook/Meal Prep{" "}
              <br className="d-none d-md-block" /> Upto 4 Meals or Less, Each
              for Serving Size of Upto 5 People or Less,
            </h5>
            <h4
              className={"text-center fw-bold " + (isMobile ? "fs-3" : "fs-2")}
            >
              {2 === 1 ? (
                `$${"2" ?? "116"}`
              ) : (
                <>
                  You Selected <span >"{planName??'One Time Booking'}"</span>
                </>
              )}
            </h4>
            <h5 className="text-center mt-3 fw-bold">
              Find Chefs In And Around Your Booking Address Location
            </h5>
           
            <div
              className="row d-flex justify-content-center mt-3"
              style={{ width: isMobile ? "95%" : "35%" }}
            >
             

              <div className="col-sm-6">
                {filterAddress.postal_code ? (
                  <button
                    className="bg-white m-1"
                    style={filterButtonStyle}
                    onClick={() => setOpenAddressDialog(true)}
                  >
                    {filterAddress.postal_code}
                  </button>
                ) : (
                  <button
                    className="bg-white m-1"
                    style={filterButtonStyle}
                    onClick={() => setOpenAddressDialog(true)}
                  >
                    {" "}
                    <MdLocationOn /> &nbsp;&nbsp; Address/Zip Code
                  </button>
                )}
              </div>

              <div className="col-sm-6">
                {cuisine ? (
                  <button
                    className="bg-white m-1"
                    style={filterButtonStyle}
                    data-bs-toggle="modal"
                    data-bs-target="#CuisineSelectModal"
                  >
                    {cuisine.split("-")[1]}
                  </button>
                ) : (
                  <button
                    className="bg-white m-1"
                    style={filterButtonStyle}
                    data-bs-toggle="modal"
                    data-bs-target="#CuisineSelectModal"
                  >
                    <RiRestaurantLine />
                    &nbsp;&nbsp; Cuisine/Diet Type
                  </button>
                )}
              </div>
              <div
                className={
                  isMobile
                    ? "col-sm-6"
                    : "col-sm-12 d-flex justify-content-center"
                }
              >
                <button
                  className="m-1 mt-4"
                  onClick={() => {
                    updateChefs();
                  }}
                  style={{
                    width: isMobile ? "100%" : "60%",
                    borderRadius: "30px",
                    padding: "10px",
                    background: "rgba(255, 94, 65, 1)",
                    color: "#fff",
                    border: "none",
                    fontSize: "0.9rem",
                    fontWeight: 400,
                    cursor: "pointer",
                  }}
                >
                  Show Nearby Chefs
                </button>
              </div>
              <div
                className={
                  isMobile
                    ? "col-sm-6 text-center mt-2"
                    : "col-sm-12 d-flex text-center justify-content-center mt-2"
                }
              >
                {showFilter ? (
                  <a
                    className=""
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      localStorage.removeItem("food_categories");
                      setCuisine("");
                      setDate("");
                      localStorage.removeItem("booking_date");
                      setFilterAddress({
                        postal_code: "",
                        line1: "",
                        country: "US",
                        latitude: "",
                        longitude: "",
                      });
                      updateChefs(true);
                      setShowFilter(false);
                    }}
                  >
                    Clear Filter
                  </a>
                ) : null}
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row my-5 py-5">
              {chefs && chefs.length > 0 ? (
                chefs.map((item, index) => {
                  return (
                    <ChefCard
                      key={item.chef_id}
                      data={item}
                      guest={false}
                      option={addressFromHomePage ?? null}
                    />
                  );
                })
              ) : (
                <>
                  <div className='text-center my-3'>
                    <img src={chefImage} className="img-fluid mx-2" alt="" />
                  </div>

                  <h5 className="text-center">
                    No chef is available for the selected Cuisine.
                  </h5>
                </>
              )}
            </div>
          </div>
          {localStorage.getItem("role")==='1' ? ( <ContactUsPop  /> ) : null}
          {/* <ContactUsPop underReview={false} /> */}
          <Footer top={1} />
        </>
      )}
    </>
  );
}

export default ChefList;
